import { createRouter, createWebHistory } from 'vue-router';

import Home from './pages/Home.vue';
const routes = [
  {
    name: '',
    path: '/',
    component: Home,
  },
  {
    name: 'home',
    path: '/home',
    component: Home,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  },
});

export default router;
