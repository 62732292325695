import { createApp } from 'vue';

import App from './App.vue';
import router from './router';
import axios from 'axios';
import VueGtag from 'vue-gtag';
import './index.css';

axios.defaults.baseURL = process.env.VUE_APP_CORETHINGS_API;

const app = createApp(App);
app.use(router);
app.use(VueGtag, { config: { id: 'UA-241285837-1' } });

function callbackRedirect(appState) {
  router.push(appState && appState.targetUrl ? appState.targetUrl : '/');
}

app.config.productionTip = false;
app.mount('#app');
